<template>

  <div>

    <b-list-group>

      <b-list-group-item
        class="flex-column align-items-start"
        v-for="task in tasksList"
        :key="task.frsId"
      >


        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1 text-uppercase">
            {{ getTaskTitle(task) }}
          </h5>
          <b-badge
            :variant="getToDoLightVariant(task.frsTarea)"
            class="badge-glow text-uppercase"
          > {{ getToDoName(task.frsTarea) }}</b-badge>
        </div>

        <template v-if="task.roles.length > 0">
          <b-card-text class="mb-1">
            <b-badge
                variant="light-secondary"
                v-for="rol in task.roles"
                :key="rol.rolId"
                class="mr-2"
            >
              {{ rol.rolNombre }}
            </b-badge>
          </b-card-text>
        </template>


        <small class="text-secondary">{{ task.frsJustificacion }}</small>

          <template v-if="isCreationForm">
              <div>
                  <tarea-to-do-item :task="task" :is-parent-busy="isBusy"/>
              </div>
          </template>



      </b-list-group-item>


    </b-list-group>


    <b-row class="mt-1" v-if="isCreationForm">

      <b-col>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            variant="primary"
            class="mb-1"
            @click="handleSendEmail"
            :disabled="isBusy"
        >
          <b-spinner small v-if="isBusy"/>
          Enviar credenciales al colaborador
        </b-button>
      </b-col>
    </b-row>





  </div>

</template>

<script>

import {BListGroup, BListGroupItem, BCardText, BBadge, BCol, BRow, BButton, BSpinner} from 'bootstrap-vue'
import { resolveToDoName, resolveToDoVariant } from '@/modules/common/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'

import { mapActions } from 'vuex'
import { sendEmailCredencialesColaborador } from '@/modules/ejecutante/store/tareas/actions'

export default {

  name: 'TareaToDoList',

  props: {
    ftyId: {
          type: Number,
          required: true,
          default: 0
    },
    frmId: {
      type: Number,
      required: true,
      default: 0
    },
    tasksList: {
      type: Array,
      required: true,
      default: []
    }
  },

  components: {
    BSpinner,
    BListGroup,
    BListGroupItem,
    BCardText,
    BBadge,
    BRow,
    BCol,
    BButton,
    TareaToDoItem: () => import('@/modules/ejecutante/views/tareas/TareaToDoItem.vue'),
    ValidationErrors,
    ToastificationContent,
  },

  directives: {
    Ripple,
  },

  data(){
    return {

      isBusy: false,

    }
  },

  computed: {

    isCreationForm() {
      return this.ftyId === 1
    },

  },

  methods: {

    ...mapActions('tasks-module', ['sendEmailCredencialesColaborador']),



    // establecer tipo de tarea acceso/office
    getTaskTitle( task ){

      if ( task.sysId ) return task.sysNombre

      return task.officeDestination

    },

    //nombre de la tarea/to-do
    getToDoName(task){
      return resolveToDoName(task)
    },

    //estilo de la tarea/to-do
    getToDoLightVariant(task){
      return resolveToDoVariant(task)
    },



    //procesar peticion de enviar correo
    async handleSendEmail() {
      try {

        this.isBusy = true

        await this.sendEmailCredencialesColaborador({frmId: this.frmId})

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Credenciales',
            icon: 'CheckIcon',
            text: '¡Credenciales enviadas al colaborador!',
            variant: 'success'
          },
        })

      } catch (error) {

        console.log("UNEXPECTED", error)

      } finally {
         this.isBusy = false
      }
    }
  }

}
</script>



<style scoped lang="scss">

</style>
